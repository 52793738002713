import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { IAssetConfig } from '@core/models/interfaces/config.interface';
import { catchError, Observable, of, tap } from 'rxjs';

export class AssetsService<T> {

  private _httpClient: HttpClient = inject(HttpClient);

  private _config: T;

  get config(): T {
    return this._config;
  }

  retrieveConfig(config: IAssetConfig, name: string, defaultValue: T): Observable<T> {
    const observable$: Observable<T> = config.useRemote ? this._loadFromRemote(config.remoteUrl, name, defaultValue) : this._loadFromAssets(config.assetsUrl, name, defaultValue);
    return observable$.pipe(
      tap(config => this._config = config)
    )
  }

  private _loadFromRemote(url: string, name: string, defaultValue: T): Observable<T> {
    return this._withFallback(this._httpClient.get<T>(`${url}/${name}`), this._loadFromAssets(url, name, defaultValue))
  }

  private _loadFromAssets(url: string, name: string, defaultValue: T): Observable<T> {
    return this._withFallback(this._httpClient.get<T>(`${url}/${name}`), of(defaultValue));
  }

  private _withFallback(observable$: Observable<T>, fallback$: Observable<T>): Observable<T> {
    return observable$.pipe(catchError(() => fallback$));
  }

}
