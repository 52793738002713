import * as i0 from '@angular/core';
import { Injectable, Inject, inject, ElementRef, Directive, Input, HostListener, EventEmitter, Output, Pipe, InjectionToken, NgModule } from '@angular/core';
import * as i1 from '@jsverse/transloco-locale';
import { TRANSLOCO_LOCALE_CONFIG, TranslocoLocaleModule } from '@jsverse/transloco-locale';
import { trigger, state, style, AUTO_STYLE, transition, animate } from '@angular/animations';
import deepEqual from 'deep-equal';
import { v4 } from 'uuid';
import { CommonModule, KeyValuePipe } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';
const Breakpoints = {
  MIN_WIDTH: {
    SM: 576,
    MD: 768,
    LG: 992,
    XL: 1200,
    XXL: 1400
  },
  MAX_WIDTH: {
    XS: 575.98,
    SM: 767.98,
    MD: 991.98,
    LG: 1199.98,
    XL: 1399.98
  }
};
let service;
let localeConfig;
function getDefaultOptions(locale, type, localeConfig) {
  const defaultConfig = localeConfig.global ? localeConfig.global[type] : {};
  const settings = localeConfig.localeBased ? localeConfig.localeBased[locale] : {};
  return settings?.[type] ? {
    ...defaultConfig,
    ...settings[type]
  } : defaultConfig;
}
function translateDate(date, options = {}, locale) {
  locale = locale || service.getLocale();
  return service.localizeDate(date, locale, {
    ...getDefaultOptions(locale, 'date', localeConfig),
    ...options
  });
}
class TranslationDateService {
  constructor(_localeConfig, _service) {
    this._localeConfig = _localeConfig;
    this._service = _service;
    localeConfig = _localeConfig;
    service = _service;
  }
  static {
    this.ɵfac = function TranslationDateService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TranslationDateService)(i0.ɵɵinject(TRANSLOCO_LOCALE_CONFIG), i0.ɵɵinject(i1.TranslocoLocaleService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TranslationDateService,
      factory: TranslationDateService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TranslationDateService, [{
    type: Injectable
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [TRANSLOCO_LOCALE_CONFIG]
    }]
  }, {
    type: i1.TranslocoLocaleService
  }], null);
})();
const DEFAULT_DURATION = 300;
const collapse = trigger('collapse', [state('false', style({
  height: '0',
  visibility: 'hidden',
  overflow: 'hidden'
})), state('true', style({
  height: AUTO_STYLE,
  visibility: AUTO_STYLE
})), transition('false => true', animate(`${DEFAULT_DURATION}ms ease-in-out`)), transition('true => false', animate(`${DEFAULT_DURATION}ms ease-out`))]);
const hide = trigger('hide', [state('false', style({
  visibility: 'hidden',
  transform: AUTO_STYLE
})), state('true', style({
  visibility: 'visible',
  transform: 'none'
})), transition('false => true', animate(`${DEFAULT_DURATION}ms ease-in-out`)), transition('true => false', animate(`${DEFAULT_DURATION}ms ease-out`))]);
const slideInOut = trigger('slideInOut', [state('false', style({
  visibility: 'hidden',
  opacity: 0
}), {
  params: {
    sign: '+'
  }
}), state('true', style({
  visibility: AUTO_STYLE,
  opacity: 1
})), transition('false => true', [style({
  transform: 'translateY({{sign}}10px)',
  opacity: 0
}), animate('.12s ease-in')]), transition('true => false', animate('.1s ease-out', style({
  transform: 'translateY({{sign}}5px)',
  opacity: 0
})))]);
const fadeIn = trigger('fadeIn', [state('in', style({
  opacity: 1
})), transition(':enter', [style({
  opacity: 0
}), animate(300)]), transition(':leave', animate(200, style({
  opacity: 0
})))]);
const AnimationUtils = {
  collapse,
  hide,
  slideInOut,
  fadeIn
};

/** Returns `true` if the given dates are equal */
const isEqual = (firstDate, secondDate) => new Date(firstDate)?.toString() === new Date(secondDate)?.toString();
/** Return the start of a day for the given date */
const startOfDay = date => !!date ? new Date(new Date(date).setHours(0, 0, 0)) : null;
/** Subtract the specified number of months from the given date */
const subMonths = (date, months) => {
  const newDate = new Date(date);
  return !!newDate ? new Date(date.setMonth(date.getMonth() - months)) : null;
};
const currentDateByHours = (hours, minutes, seconds) => {
  let date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(seconds);
  return date;
};
const TODAY = startOfDay(new Date());
const DateUtils = {
  TODAY,
  isEqual,
  startOfDay,
  subMonths,
  currentDateByHours
};
const isObject = value => !!value && !Array.isArray(value) && typeof value === 'object';
const getProperty = (obj, path) => !!path ? path.split('.').reduce((o, p) => o && o[p], obj) : obj;
const equal = (firstObj, secondObj) => deepEqual(firstObj, secondObj);
const ObjectUtils = {
  isObject,
  getProperty,
  equal
};
const generateId = (context, id) => {
  return !!id || id === 0 ? `nsa-${context}-${id}` : `nsa-${context}-${v4()}`;
};
const hasValue = value => {
  return typeof value === 'undefined' || typeof value === 'object' || typeof value === 'string' ? !!value : true;
};
const SharedUtils = {
  generateId,
  hasValue
};
const timepickerValidator = (min = {
  hours: 1,
  minutes: 0
}, max = {
  hours: 23,
  minutes: 59
}) => control => {
  if (!control.value) return null;else {
    const value = control.value;
    const valueDate = DateUtils.currentDateByHours(value.hours, value.minutes, 0);
    const minDate = DateUtils.currentDateByHours(min.hours, min.minutes, 0);
    const maxDate = DateUtils.currentDateByHours(max.hours, max.minutes, 0);
    return valueDate.getTime() < minDate.getTime() || valueDate.getTime() > maxDate.getTime() ? {
      timepicker: true
    } : null;
  }
};
const ValidatorUtils = {
  timepickerValidator
};
class OnlyNumbersDirective {
  constructor() {
    this.allowMultiLine = false;
    this.allowNegative = false;
    this.allowDecimal = false;
    this.maxlength = 0;
    this._el = inject(ElementRef);
  }
  onKeyPress(event) {
    this.validate(event, event.key === 'Enter' ? '\n' : event.key);
  }
  onPaste(event) {
    const pastedText = window.clipboardData && window.clipboardData.getData('Text') // If IE, use window
    || event && event.clipboardData.getData('text/plain'); // Non-IE browsers
    this.validate(event, pastedText);
  }
  onCut(event) {
    this.validate(event, '');
  }
  validate(event, text) {
    const txtInput = this._el.nativeElement;
    const newValue = txtInput.value.substring(0, txtInput.selectionStart) + text + txtInput.value.substring(txtInput.selectionEnd);
    if (!this.regex) {
      this.regex = (0, eval)('/^' + (this.allowNegative ? '-?' : '') + (this.allowDecimal ? '((\\d+\\.?)|(\\.?))\\d*' : '\\d*') + '$/g');
    }
    const lines = this.allowMultiLine ? newValue.split('\n') : [newValue];
    for (const line of lines) {
      const lineText = line.replace('\r', '');
      console.log(this.maxlength);
      if (this.maxlength && lineText.length > this.maxlength || !lineText.match(this.regex)) {
        event.preventDefault();
        return;
      }
    }
  }
  static {
    this.ɵfac = function OnlyNumbersDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || OnlyNumbersDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: OnlyNumbersDirective,
      selectors: [["", "nsaOnlyNumbers", ""]],
      hostBindings: function OnlyNumbersDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("keypress", function OnlyNumbersDirective_keypress_HostBindingHandler($event) {
            return ctx.onKeyPress($event);
          })("paste", function OnlyNumbersDirective_paste_HostBindingHandler($event) {
            return ctx.onPaste($event);
          })("cut", function OnlyNumbersDirective_cut_HostBindingHandler($event) {
            return ctx.onCut($event);
          });
        }
      },
      inputs: {
        allowMultiLine: "allowMultiLine",
        allowNegative: "allowNegative",
        allowDecimal: "allowDecimal",
        maxlength: "maxlength"
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OnlyNumbersDirective, [{
    type: Directive,
    args: [{
      selector: '[nsaOnlyNumbers]'
    }]
  }], null, {
    allowMultiLine: [{
      type: Input
    }],
    allowNegative: [{
      type: Input
    }],
    allowDecimal: [{
      type: Input
    }],
    maxlength: [{
      type: Input
    }],
    onKeyPress: [{
      type: HostListener,
      args: ['keypress', ['$event']]
    }],
    onPaste: [{
      type: HostListener,
      args: ['paste', ['$event']]
    }],
    onCut: [{
      type: HostListener,
      args: ['cut', ['$event']]
    }]
  });
})();
class OutsideClickDirective {
  constructor(_elementRef) {
    this._elementRef = _elementRef;
    this.emitEvent = true;
    this.includedElements = [];
    this.outsideClick = new EventEmitter();
  }
  onClick(target) {
    if (this.emitEvent && !this._contains(target)) this.outsideClick.emit();
  }
  _contains(target) {
    const elements = [...this.includedElements, this._elementRef.nativeElement];
    return elements.some(el => el.contains(target));
  }
  static {
    this.ɵfac = function OutsideClickDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || OutsideClickDirective)(i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: OutsideClickDirective,
      selectors: [["", "nsaOutsideClick", ""]],
      hostBindings: function OutsideClickDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("click", function OutsideClickDirective_click_HostBindingHandler($event) {
            return ctx.onClick($event.target);
          }, false, i0.ɵɵresolveDocument)("touchstart", function OutsideClickDirective_touchstart_HostBindingHandler($event) {
            return ctx.onClick($event.target);
          }, false, i0.ɵɵresolveDocument);
        }
      },
      inputs: {
        emitEvent: [0, "nsaOutsideClick", "emitEvent"],
        includedElements: "includedElements"
      },
      outputs: {
        outsideClick: "outsideClick"
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OutsideClickDirective, [{
    type: Directive,
    args: [{
      selector: '[nsaOutsideClick]'
    }]
  }], () => [{
    type: i0.ElementRef
  }], {
    emitEvent: [{
      type: Input,
      args: ['nsaOutsideClick']
    }],
    includedElements: [{
      type: Input
    }],
    outsideClick: [{
      type: Output
    }],
    onClick: [{
      type: HostListener,
      args: ['document:click', ['$event.target']]
    }, {
      type: HostListener,
      args: ['document:touchstart', ['$event.target']]
    }]
  });
})();
class PaginatePipe {
  transform(items, params) {
    const {
      pagination,
      serverSide,
      currentPage,
      pageSize
    } = params;
    if (!!pagination && !serverSide) {
      return [...items.slice((currentPage - 1) * pageSize, currentPage * pageSize)];
    } else {
      return [...items];
    }
  }
  static {
    this.ɵfac = function PaginatePipe_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PaginatePipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "nsaPaginate",
      type: PaginatePipe,
      pure: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PaginatePipe, [{
    type: Pipe,
    args: [{
      name: 'nsaPaginate'
    }]
  }], null, null);
})();
class TableActionHiddenPipe {
  transform(actions, params) {
    const {
      item
    } = params;
    return [...actions].filter(action => !action.isHidden?.(item));
  }
  static {
    this.ɵfac = function TableActionHiddenPipe_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TableActionHiddenPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "nsaTableActionHidden",
      type: TableActionHiddenPipe,
      pure: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TableActionHiddenPipe, [{
    type: Pipe,
    args: [{
      name: 'nsaTableActionHidden'
    }]
  }], null, null);
})();
const TRANSLATION_FORM_ERROR_PREFIX = new InjectionToken('translationFormErrorPrefix');
class SharedModule {
  static {
    this.ɵfac = function SharedModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SharedModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: SharedModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [KeyValuePipe, PaginatePipe, TableActionHiddenPipe],
      imports: [CommonModule, CommonModule, TranslocoModule, TranslocoLocaleModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SharedModule, [{
    type: NgModule,
    args: [{
      declarations: [OutsideClickDirective, OnlyNumbersDirective, PaginatePipe, TableActionHiddenPipe],
      imports: [CommonModule],
      exports: [CommonModule, TranslocoModule, TranslocoLocaleModule, OutsideClickDirective, OnlyNumbersDirective, PaginatePipe, TableActionHiddenPipe],
      providers: [KeyValuePipe, PaginatePipe, TableActionHiddenPipe]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { AnimationUtils, Breakpoints, DateUtils, ObjectUtils, OnlyNumbersDirective, OutsideClickDirective, PaginatePipe, SharedModule, SharedUtils, TRANSLATION_FORM_ERROR_PREFIX, TableActionHiddenPipe, TranslationDateService, ValidatorUtils, translateDate };
