import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, Input, NgModule } from '@angular/core';
import * as i1 from '@angular/common';
import * as i2 from '@jsverse/transloco';
import { SharedModule } from '@nesea/ngx-ui-kit/shared';
const _c0 = ["*"];
const _c1 = a0 => ({
  "card-detail": a0
});
function CardComponent_Conditional_1_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
    i0.ɵɵpipe(1, "transloco");
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind2(1, 1, ctx_r0.label, ctx_r0.translationParams), " ");
  }
}
function CardComponent_Conditional_1_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵtextInterpolate1(" ", ctx_r0.translatedLabel, " ");
  }
}
function CardComponent_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 1);
    i0.ɵɵtemplate(1, CardComponent_Conditional_1_Conditional_1_Template, 2, 4)(2, CardComponent_Conditional_1_Conditional_2_Template, 1, 1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵconditional(!!ctx_r0.label ? 1 : 2);
  }
}
function CardComponent_Conditional_3_Conditional_0_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
    i0.ɵɵpipe(1, "transloco");
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(3);
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind2(1, 1, ctx_r0.label, ctx_r0.translationParams), " ");
  }
}
function CardComponent_Conditional_3_Conditional_0_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(3);
    i0.ɵɵtextInterpolate1(" ", ctx_r0.translatedLabel, " ");
  }
}
function CardComponent_Conditional_3_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "h5", 4);
    i0.ɵɵtemplate(1, CardComponent_Conditional_3_Conditional_0_Conditional_1_Template, 2, 4)(2, CardComponent_Conditional_3_Conditional_0_Conditional_2_Template, 1, 1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵconditional(!!ctx_r0.label ? 1 : 2);
  }
}
function CardComponent_Conditional_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, CardComponent_Conditional_3_Conditional_0_Template, 3, 1, "h5", 4);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵconditional(!!ctx_r0.label || !!ctx_r0.translatedLabel ? 0 : -1);
  }
}
class CardComponent {
  constructor() {
    this.type = 'simple';
    this.translationParams = {};
    this.className = '';
  }
  get isDetail() {
    return this.type === 'detail';
  }
  static {
    this.ɵfac = function CardComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CardComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CardComponent,
      selectors: [["nsa-card"]],
      inputs: {
        nsaId: "nsaId",
        type: "type",
        label: "label",
        translatedLabel: "translatedLabel",
        translationParams: "translationParams",
        className: "className"
      },
      ngContentSelectors: _c0,
      decls: 6,
      vars: 9,
      consts: [[3, "id", "ngClass"], [1, "card-header"], [1, "card-body"], [1, "card-text"], [1, "card-title", "text-secondary", "fw-bold", "pb-4"]],
      template: function CardComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵtemplate(1, CardComponent_Conditional_1_Template, 3, 1, "div", 1);
          i0.ɵɵelementStart(2, "div", 2);
          i0.ɵɵtemplate(3, CardComponent_Conditional_3_Template, 1, 1);
          i0.ɵɵelementStart(4, "div", 3);
          i0.ɵɵprojection(5);
          i0.ɵɵelementEnd()()();
        }
        if (rf & 2) {
          i0.ɵɵclassMapInterpolate1("card ", ctx.className, "");
          i0.ɵɵproperty("id", ctx.nsaId)("ngClass", i0.ɵɵpureFunction1(7, _c1, ctx.isDetail));
          i0.ɵɵadvance();
          i0.ɵɵconditional(ctx.isDetail ? 1 : -1);
          i0.ɵɵadvance(2);
          i0.ɵɵconditional(!ctx.isDetail ? 3 : -1);
        }
      },
      dependencies: [i1.NgClass, i2.TranslocoPipe],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CardComponent, [{
    type: Component,
    args: [{
      selector: 'nsa-card',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<div\r\n  [id]=\"nsaId\"\r\n  class=\"card {{className}}\"\r\n  [ngClass]=\"{ 'card-detail': isDetail }\">\r\n  @if(isDetail) {\r\n    <div class=\"card-header\">\r\n      @if(!!label) {\r\n        {{ label | transloco: translationParams }}\r\n      } @else {\r\n        {{ translatedLabel }}\r\n      }\r\n    </div>\r\n  }\r\n  <div class=\"card-body\">\r\n    @if(!isDetail) {\r\n      @if(!!label || !!translatedLabel) {\r\n        <h5 class=\"card-title text-secondary fw-bold pb-4\">\r\n          @if(!!label) {\r\n            {{ label | transloco: translationParams }}\r\n          } @else {\r\n            {{ translatedLabel }}\r\n          }\r\n        </h5>\r\n      }\r\n    }\r\n\r\n    <div class=\"card-text\">\r\n      <ng-content></ng-content>\r\n    </div>\r\n  </div>\r\n</div>\r\n"
    }]
  }], null, {
    nsaId: [{
      type: Input,
      args: [{
        required: true
      }]
    }],
    type: [{
      type: Input,
      args: [{
        required: true
      }]
    }],
    label: [{
      type: Input
    }],
    translatedLabel: [{
      type: Input
    }],
    translationParams: [{
      type: Input
    }],
    className: [{
      type: Input
    }]
  });
})();
class CardJumboComponent {
  constructor() {
    this.translationParams = {};
    this.className = '';
  }
  static {
    this.ɵfac = function CardJumboComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CardJumboComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CardJumboComponent,
      selectors: [["nsa-card-jumbo"]],
      inputs: {
        nsaId: "nsaId",
        icon: "icon",
        label: "label",
        translationParams: "translationParams",
        className: "className"
      },
      decls: 5,
      vars: 9,
      consts: [[3, "id"], [1, "card-body"], [1, "card-title", "fw-bold"]],
      template: function CardJumboComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0)(1, "div", 1)(2, "h5", 2);
          i0.ɵɵtext(3);
          i0.ɵɵpipe(4, "transloco");
          i0.ɵɵelementEnd()()();
        }
        if (rf & 2) {
          i0.ɵɵclassMapInterpolate2("card card-jumbo mb-4 nsa-", ctx.icon, " ", ctx.className, "");
          i0.ɵɵproperty("id", ctx.nsaId);
          i0.ɵɵadvance(3);
          i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind2(4, 6, ctx.label, ctx.translationParams), " ");
        }
      },
      dependencies: [i2.TranslocoPipe],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CardJumboComponent, [{
    type: Component,
    args: [{
      selector: 'nsa-card-jumbo',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<div\r\n  [id]=\"nsaId\"\r\n  class=\"card card-jumbo mb-4 nsa-{{icon}} {{className}}\">\r\n  <div class=\"card-body\">\r\n    <h5 class=\"card-title fw-bold\">\r\n      {{ label | transloco: translationParams }}\r\n    </h5>\r\n  </div>\r\n</div>\r\n"
    }]
  }], null, {
    nsaId: [{
      type: Input,
      args: [{
        required: true
      }]
    }],
    icon: [{
      type: Input,
      args: [{
        required: true
      }]
    }],
    label: [{
      type: Input,
      args: [{
        required: true
      }]
    }],
    translationParams: [{
      type: Input
    }],
    className: [{
      type: Input
    }]
  });
})();
class CardModule {
  static {
    this.ɵfac = function CardModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CardModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CardModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [SharedModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CardModule, [{
    type: NgModule,
    args: [{
      declarations: [CardComponent, CardJumboComponent],
      imports: [SharedModule],
      exports: [CardComponent, CardJumboComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CardComponent, CardJumboComponent, CardModule };
